<template>
  <div class="sign-in-overlay">
    <div class="form-container" :class="{ shake: errorMessage }">

      <div class="federated-signin">
        <h1>We need to know each other closer first...</h1>
        <p>By continuing, you're setting up an AI Nikki account and agree to our
          <a href="/user-agreement.html" class="link">User Agreement</a> and
          <a href="/privacy.html" class="link">Privacy Policy</a>.
        </p>
        <div class="checkbox-container">
          <input class="checkbox" type="checkbox" id="email-agreement" v-model="agreesToEmails"/>
          <label for="email-agreement">I agree to get emails from Nikki</label>
        </div>
        <div class="error-message-container">
          <p class="error-message" :class="{ visible: errorMessage }">{{ errorMessage }}</p>
        </div>
        <div class="button-container">
          <button class="federated-button google-button" @click="signInWithGoogle">
            <img src="/icons/google.png" alt="Google Icon" class="federation-icon"/>
            CONTINUE WITH GOOGLE
          </button>
          <button class="back-button" @click="goToHome">Back to Home</button>
        </div>

      </div>

    </div>
  </div>
</template>

<script setup>
import {ref, watch} from 'vue';

function generateRandomState() {
  return 'state_' + Math.random().toString(36).substr(2, 9);
}

const agreesToEmails = ref(false);
const errorMessage = ref('');

watch(agreesToEmails, (newVal) => {
  if (newVal) {
    errorMessage.value = '';
  }
});

function signInWithGoogle() {
  if (!agreesToEmails.value) {
    errorMessage.value = 'You must agree to get emails from Nikki before continuing.';
    triggerShake();
  } else {
    const state = generateRandomState();
    sessionStorage.setItem('oauth_state', state);
    sessionStorage.setItem('agreesToEmails', agreesToEmails.value);
    // const redirectUri = encodeURIComponent(`${window.location.origin || `true-love.ai`}/callback.html`);
    const redirectUri = encodeURIComponent(`${window.location.origin ? window.location.origin : 'https://true-love.ai'}/callback.html`);
    console.log(redirectUri)
    const scope = 'email openid profile';
    // const clientId = '3gi5egb4hnhf782bvm524h23ol'
    const clientId = '4i9jhsj7b8gqs2rvq2nr7rrjom'
    // Redirect to Cognito for authentication
    // const authUrl = `https://true-love-auth.auth.us-west-2.amazoncognito.com/oauth2/authorize?` +
    //                     `response_type=token&` +
    //                     `client_id=${clientId}&` +
    //                     `redirect_uri=${redirectUri}&` +
    //                     `state=${state}&` +
    //                     `scope=${scope}&` +
    //                     `identity_provider=Google`;
    const authUrl = `https://true-love-ai-auth.auth.us-west-2.amazoncognito.com/oauth2/authorize?` +
        `response_type=token&` +
        `client_id=${clientId}&` +
        `redirect_uri=${redirectUri}&` +
        `state=${state}&` +
        `scope=${scope}&` +
        `identity_provider=Google`;
    localStorage.setItem('clientId', clientId)
    window.location.href = authUrl;

    console.log('Signing in with Google');
  }
}

function triggerShake() {
  const formContainer = document.querySelector('.form-container');
  formContainer.classList.add('shake');
  setTimeout(() => {
    formContainer.classList.remove('shake');
  }, 350);
}

function goToHome() {
  window.location.href = '/';
}
</script>

<style scoped src="./signIn.css"/>

